const awscognito = {
    Auth: {
        identityPoolId: "us-east-1:36e4bf51-5686-45d2-9d82-d4cc09422d8b",
        region: "us-east-1",
        userPoolId: "us-east-1_Bmknmjw1n",
        userPoolWebClientId: "28a79t5nnifros724oencb8786",
        authenticationFlowType: 'USER_PASSWORD_AUTH'
    },
    Storage: {
        AWSS3: {
            bucket: 'markcrm-upload-test',
            region: 'us-east-1'
        }
    }
};

export default awscognito;
import React, { useState } from "react"

import ReportsModule from "../../components/reports"

const Reports = () => { 

    let [loading, setLoading] = useState(false)

    React.useEffect(() => {

    }, [])

    return (
        <div className="container-fluid m-0 py-5 p-0 p-md-3bg-light">
            <div className="row">
                <div className="col-12"> 

                    <div className="container m-0 p-0 mx-auto">
                        <div className="row">
                            <div className="col-12">

                                <ReportsModule />

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )

}

export default Reports;
import React, { useState } from "react"
import axios from "axios"
import { Link, useHistory } from "react-router-dom"

const CreateConnection = ({ callback = null, connectionId = null }) => {

    let [loading, setLoading] = useState(false)

    const [ini_state,] = useState({
        "type": "mysql",
        "name": "",
        "credentials": {}
    })

    let [connection, setConnection] = useState(ini_state)

    React.useEffect(() => {
    

    }, [])

    const handleFieldChange = (e, key) => {

        connection = {...connection}

        if(key.includes("credentials")){
            connection["credentials"][key.split(".")[1]] = e.target.value
        } else {
            connection[key] = e.target.value
        }
        
        setConnection(connection)

    }

    const saveField = (e, key) => {

    }

    let history = useHistory()

    const createConnection = (e, key) => {

        e.preventDefault()

        axios.post(window.REACT_APP_API_ENDPOINT + "/connections", connection).then(res => {
            history.push("/connections/" + res.data.id)
        })

    }

    return (
        <>
            <div className="container-fluid mx-auto p-0">
                <div className="row">
                    <div className="col-12 text-center text-md-end">

                        <button className="btn btn-secondary rounded-pill" data-bs-toggle="modal" data-bs-target="#connectionModal"><i className="bi bi-plus-circle me-1"></i> Create a new connection</button>

                    </div>
                </div>
                <div className="row">
                    <div className="col-12">

                        <div className="modal fade" id="connectionModal" tabindex="-1" aria-labelledby="connectionModalLabel" aria-hidden="true">
                            <div className="modal-dialog" style={{ maxWidth: "900px" }}>
                                <div className="modal-content">

                                    <div className="modal-header">
                                        <h5 className="modal-title" id="connectionModalLabel">Create new connection</h5>
                                        
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>

                                    <form onSubmit={e => createConnection(e)}>

                                        <div className="modal-body">

                                            <div className="container-fluid">
                                                <div className="row mt-4">

                                                    <div className="col-12 col-md-6">

                                                        <div className="form-floating">

                                                            <select
                                                                type="text"
                                                                className={"form-control"}
                                                                name={"type"}
                                                                value={connection.type}
                                                                placeholder="Type"
                                                                onBlur={(e) => saveField(e, "type")}
                                                                onChange={(e) => handleFieldChange(e, "type")} >

                                                                <option value="aurora-mysql">Amazon Aurora - MySQL compatible</option>
                                                                <option value="aurora-postgresql">Amazon Aurora - PostgreSQL compatible</option>
                                                                <option value="mysql">MySQL</option>
                                                                <option value="postgresql">PostgreSQL</option>

                                                            </select>

                                                            <label for="type">Type</label>

                                                        </div>

                                                        <div className="form-floating mt-4">

                                                            <input
                                                                type="text"
                                                                className={"form-control"}
                                                                name={"name"}
                                                                value={connection.name}
                                                                placeholder="Connection name"
                                                                onBlur={(e) => saveField(e, "name")}
                                                                required
                                                                onChange={(e) => handleFieldChange(e, "name")} />

                                                            <label for="name">Connection name</label>

                                                        </div>

                                                        <div className="form-floating mt-4">

                                                            <input
                                                                type="text"
                                                                className={"form-control"}
                                                                name={"host"}
                                                                value={connection.credentials.host}
                                                                placeholder="Host"
                                                                required
                                                                onBlur={(e) => saveField(e, "credentials.host")}
                                                                onChange={(e) => handleFieldChange(e, "credentials.host")} />

                                                            <label for="host">Host</label>

                                                        </div>

                                                        <div className="form-floating mt-4">

                                                            <input
                                                                type="text"
                                                                className={"form-control"}
                                                                name={"database"}
                                                                value={connection.credentials.database}
                                                                placeholder="Database (optional)"
                                                                onBlur={(e) => saveField(e, "credentials.database")}
                                                                onChange={(e) => handleFieldChange(e, "credentials.database")} />

                                                            <label for="database">Database (optional)</label>

                                                        </div>

                                                    </div>
                                                    <div className="col-12 col-md-6 mt-4 mt-md-0">

                                                        <div className="form-floating">

                                                            <input
                                                                type="text"
                                                                className={"form-control"}
                                                                name={"username"}
                                                                value={connection.credentials.username}
                                                                required
                                                                placeholder="Username"
                                                                onBlur={(e) => saveField(e, "credentials.username")}
                                                                onChange={(e) => handleFieldChange(e, "credentials.username")} />

                                                            <label for="username">Username</label>

                                                        </div>

                                                        <div className="form-floating mt-4">

                                                            <input
                                                                type="password"
                                                                className={"form-control"}
                                                                name={"password"}
                                                                value={connection.credentials.password}
                                                                required
                                                                placeholder="Password"
                                                                onBlur={(e) => saveField(e, "credentials.password")}
                                                                onChange={(e) => handleFieldChange(e, "credentials.password")} />

                                                            <label for="password">Password</label>

                                                        </div>

                                                        <div className="form-floating mt-4">

                                                            <input
                                                                type="text"
                                                                className={"form-control"}
                                                                name={"port"}
                                                                value={connection.credentials.port}
                                                                placeholder="Port (optional - default 3306)"
                                                                onBlur={(e) => saveField(e, "credentials.port")}
                                                                onChange={(e) => handleFieldChange(e, "credentials.port")} />

                                                            <label for="port">Port (optional)</label>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                        <div className="modal-footer">
                                            <button type="submit" data-bs-dismiss="modal" className="btn btn-secondary mx-auto mx-md-0">Create connection</button>
                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )


}

export default CreateConnection
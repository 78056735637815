import React, { useState, useContext } from "react"
import axios from "axios"
import AceEditor from "react-ace";
import { useParams, useHistory } from "react-router-dom"
import Accordion from 'react-bootstrap/Accordion'
import UserContext from "../../context/user"

import TestConnection from "../../components/test-connection";
import CreateReport from "../../components/create-report";

import "ace-builds/src-noconflict/mode-mysql";
import "ace-builds/src-noconflict/theme-monokai";

import {AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

const Query = () => { 

    // check if id for query is in parameters

    let params = useParams();

    let [queryId, setQueryId] = useState(params.id)
    let [query, setQuery] = useState()
    let [name, setName] = useState()
        
    const [Auth_user, setAuth_user] = useContext(UserContext);

    // check if this connection id is up in the URL
    let url_params = new URLSearchParams(window.location.search)
    
    let [connectionId, setConnectionId] = useState(url_params.get("connection"))

    let [connection, setConnection] = useState()
    let [connectionLoading, setConnectionLoading] = useState(true)

    let [connectionOptions, setConnectionOptions] = useState([])
    let [connectionOptionsLoading, setConnectionOptionsLoading] = useState(true)
    

    // handle results
    let [loading, setLoading] = useState(false)
    let [results, setResults] = useState()

    React.useEffect(() => {

        getConnectionOptions();

        if(queryId){
            getQuery();
        } else {
            if(connectionId){
                getConnection(connectionId)
            } else {
                setConnectionLoading(false)
            }
        }

    }, [])

    const getQuery = () => {

        axios.get(window.REACT_APP_API_ENDPOINT + "/queries/" + queryId).then((res) => {
            setName(res.data.name)
            setQuery(res.data.query)
            getConnection(res.data.connection)
        })

    }

    const getConnection = (connectionId) => {

        setConnectionLoading(true)

        axios.get(window.REACT_APP_API_ENDPOINT + "/connections/" + connectionId).then((res) => {
            setConnection(res.data)
            setConnectionLoading(false)
        })

    }

    const getConnectionOptions = () => {

        setConnectionOptionsLoading(true)

        axios.get(window.REACT_APP_API_ENDPOINT + "/connections").then((res) => {

            getConnection(res.data[0].id)
            setConnectionOptionsLoading(false)
            setConnectionOptions(res.data)

        })

    }

    let [table, setTable] = useState({})

    const run = () => {

        setLoading(true)

        axios.post(window.REACT_APP_API_ENDPOINT + "/" + connection.type + "/query", {
            "connection": connection.id,
            "query": query
        }).then((res) => {

            setLoading(false)
            if(res.data.results.length > 0){
                setResults(res.data)

                table = {...table}
                table.headers = []
                table.data = []


                Object.keys(res.data.results[0]).map((item, idx) => {
                    table.headers.push({headerName: item, field: item})
                })

                res.data.results.map((item, idx) => {
                    table.data.push(item)
                })

                setTable(table)

            } else {
                setResults({
                    "rows": 0
                })
            }
            

        }).catch((err) => {

            results = {}
            results.error = err.response // .data.message
            console.log(err.response)
            setResults(results)
            setLoading(false)

        })

    }

    const queryChange = (value) => {
        setQuery(value)
    }

    const nameChange = (e) => {
        setName(e.target.value)
    }

    const handleConnectionChange = (e) => {

        if(url_params.get("connection")){
            window.history.pushState({}, null, window.location.href.split('?')[0]); 
        } 

        // connection = {...connection}
        // connection.id = e.target.value
        // setConnection(connection)

        getConnection(e.target.value)

    }

    const saveQuery = (e) => {

        if(queryId){

            axios.patch(window.REACT_APP_API_ENDPOINT + "/queries/" + queryId, {
                "name": name,
                "query": query,
                "connection": connection.id
            }).then((res) => {
                alert("Query has been saved")
            })

        } else {

            axios.post(window.REACT_APP_API_ENDPOINT + "/queries", {
                "name": name,
                "query": query,
                "connection": connection.id
            }).then((res) => {
                window.history.pushState('', '', '/queries/' + res.data.id);
                setQueryId(res.data.id)
                alert("Query has been saved")
            })

        }

    }

    let history = useHistory()
    const deleteQuery = (e) => {

        if(queryId){

            axios.delete(window.REACT_APP_API_ENDPOINT + "/queries/" + queryId).then((res) => {
                history.push("/queries")
            })

        }

    }

    const downloadResults = (e, type) => {

        axios({
            url: window.REACT_APP_API_ENDPOINT + "/" + connection.type + "/query/" + queryId + "/" + type, //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'results - ' + queryId + '.' + type); //or any other extension
            document.body.appendChild(link);
            link.click();
        });

    }

    const ChooseConnection = () => {

        return (
            <div className="form-floating shadow-sm">
                <select
                    type="text"
                    className={"form-control"}
                    name={"connection"}
                    value={connection && connection.id || ""}
                    placeholder={connectionLoading ? "Loading..." : "Choose connection"}
                    disabled={connectionLoading}
                    onChange={(e) => handleConnectionChange(e)} >
                    {connectionOptions && connectionOptions.map((item, index) => {
                        return (
                            <option value={item.id}>{item.name}  ({item.id})</option>
                        )
                    })}

                </select>
                <label for="connection">{connectionLoading ? "Loading..." : "Choose connection"}</label>
            </div>
        )

    }

    return (
        <>
        <div className="container-fluid mx-auto p-0 my-4">
            <div className="row px-3">
                <div className="d-none d-md-block col-3 mt-3"> 

                    {connectionOptions && <ChooseConnection />}

                    {!connectionLoading && 
                    <>
                        <div className="card radius-0 border-0 shadow-sm mt-4">
                            <div className="card-body">
                                {connection &&
                                    <TestConnection connection={connection.id} type={connection.type} />
                                }
                            </div>
                        </div>

                        <div className="container-fluid p-0 m-0 mt-4">
                            {connection && 
                                <Schema connectionId={connection.id} connectionType={connection.type} />
                            }
                        </div>
                    </>
                    }
                    

                </div>
                <div className="col-12 col-md-9">

                    <div className="d-md-none">
                    {connectionOptions && <ChooseConnection />}
                    </div>

                    <div className="container-fluid m-0 p-0 mt-3">

                        <div className="row">
                            <div className="col-12">

                                <div className="card shadow-sm rounded-0 rounded-top">
                                    <div className="card-body p-3 text-end">

                                        <div className="container-fluid p-0 m-0">
                                            <div className="row">
                                                <div className="col-12 col-md-6 text-start">

                                                    <input 
                                                        type="text"
                                                        value={name}
                                                        className="fs-5"
                                                        placeholder="Untitled query"
                                                        onChange={e => nameChange(e)}
                                                        style={{
                                                            border: "none",
                                                            width: "100%"
                                                        }}
                                                    />

                                                </div>
                                                <div className="d-none d-md-block col-6 text-end">
                                                    
                                                    {["owner", "admin", "user"].indexOf(Auth_user.default_team.role) > -1 &&
                                                    <>
                                                    <button className="btn btn-outline-danger me-3" onClick={e => deleteQuery(e)}><i class="bi bi-trash-fill"></i></button>
                                                    <button className="btn btn-outline-primary me-3" onClick={e => saveQuery(e)}><i className="bi bi-file-earmark-check-fill"></i> Save query</button>
                                                    </>
                                                    }
                                                    <button className="btn btn-success" onClick={e => run(e)}><i className="bi bi-play-fill"></i> Run query</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">

                                    <AceEditor
                                        mode="mysql"
                                        theme="monokai"
                                        onChange={value => queryChange(value)}
                                        name="sql"
                                        value={query}
                                        readOnly={loading ? true : false}
                                        style={{
                                            width: "100%",
                                            height: "200px",
                                            opacity: (loading ? "0.2" : ""),
                                            fontSize: "1rem"
                                        }}
                                        editorProps={{ $blockScrolling: true }}
                                    />

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">


                                <div className="d-md-none card shadow-sm rounded-0 rounded-bottom">
                                    <div className="card-body p-3 text-end">

                                        <div className="container-fluid p-0 m-0">
                                            <div className="row">
                                                <div className="col-12 text-center fs-4">
                                                    <button className="btn btn-danger-primary me-3" onClick={e => deleteQuery(e)}><i class="bi bi-trash-fill"></i></button>
                                                    <button className="btn btn-outline-success me-3" onClick={e => saveQuery(e)}><i className="bi bi-file-earmark-check-fill"></i> Save query</button>
                                                    <button className="btn btn-secondary" onClick={e => run(e)}><i className="bi bi-play-fill"></i> Run query</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                {results && results.error &&
                                    <div className="alert alert-danger mt-4" role="alert">
                                        <strong className="d-block">Error</strong>
                                        {results.error}
                                    </div>
                                }

                                {results && !results.error && results.rows > 1 &&
                                    <div className="alert alert-success mt-4" role="alert">
                                        <i className="bi bi-check-circle-fill me-2"></i> {results.rows} {results.rows != 1 ? "rows" : "row"} {results.results ? "found" : "affected" }
                                    </div>
                                }

                                {results && !results.error && results.rows < 1 &&
                                    <div className="alert alert-success mt-4" role="alert">
                                        <i className="bi bi-check-circle-fill me-2"></i> Query succesfully executed - {results.rows} rows returned
                                    </div>
                                }

                                
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-12 p-0 m-0 p-md-1 m-md-0">

                                {!loading && results && results.results && results.rows > 0 &&
                                <>


                                <div className="container-fluid m-0 p-0">
                                    <div className="row">
                                        <div className="col-8 text-start">

                                            <button type="button" onClick={e => downloadResults(e, "csv")}  className="btn btn-link">Download as CSV</button> | 
                                            <button type="button" onClick={e => downloadResults(e, "json")}  className="btn btn-link">Download as JSON</button>

                                        </div>
                                        <div className="col-4 text-end">

                                            <CreateReport queryId={queryId} />

                                        </div>
                                    </div>
                                </div>

                                <div className="card mt-2 p-0">
                                    <div className="card-body p-0 text-start">

                                        <div className="ag-theme-balham" style={{ width: '100%', minHeight: "250px"}}>
                                            <AgGridReact
                                                defaultColDef={{ resizable: true, sortable: true, filter: true }}
                                                columnDefs={table.headers}
                                                style={{minHeight: "250px"}}
                                                paginationPageSize={25}
                                                maintainColumnOrder="true"
                                                pagination={results.rows > 25 ? true : false}
                                                domLayout='autoHeight'
                                                onRowClicked={(e) => console.log(e)}
                                                enableCellTextSelection="true"
                                                rowData={table.data}>
                                                    
                                            </AgGridReact>                                        
                                        </div>

                                    </div>
                                </div>

                                </>
                                }
                                
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>

        </>
    )

}

export default Query;


{/* <table className="table table-hover mt-3" style={{ fontSize: "14px" }}>
<thead>
    {Object.keys(results.results[0]).map((item, idx) => {
        return (
            <th className="p-2 fw-bold text-start">{item}</th>
        )
    })}
</thead>
<tbody>
{results.results && results.results.map((item, idx) => {
    return (
        <tr>
            {Object.keys(results.results[0]).map((key, idx) => {
                return (
                    <td className="p-2 text-start border border-1" style={{whiteSpace: "nowrap"}}>{item[key]}</td>
                )
            })}

        </tr>
    )
})}
</tbody>
</table> */}

const Schema = ({ connectionId, connectionType }) => {

    let [schema, setSchema] = useState()
    let [loading, setLoading] = useState(false)

    React.useEffect(() => {

        if(connectionId){
            getSchema(connectionId, connectionType)
        }

    }, [connectionId])

    const getSchema = (connectionId, connectionType) => {

        setLoading(true)
        setSchema()

        axios.get(window.REACT_APP_API_ENDPOINT + "/" + connectionType + "/schema", {
            "params": {
                "connection": connectionId
            }
        }).then((res) => {
            setSchema(res.data)
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
        })

    }

    return (
        <div className={"container-fluid card shadow-sm " + (connectionId ? "" : "d-none")}>
            <div className="row">
                <div className="col-12 p-0 m-0 py-3">

                    <div className="container-fluid m-0 mb-2 p-0 ps-3">
                        <div className="row">
                            <div className="col-10">
                                <small className="text-black-50">{(("postgresql".indexOf(connectionType) > -1)) ? "Schemas" : "Databases"}</small>
                            </div>
                            <div className="col-2">

                                {connectionId && !loading &&
                                <i className="bi bi-arrow-clockwise" onClick={e => getSchema(connectionId, connectionType)} style={{ cursor: "pointer"}}></i>
                                }

                                {loading && 
                                <div className="spinner-border spinner-border-sm text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                }

                            </div>
                        </div>
                    </div>

                    {schema &&
                    <Accordion defaultActiveKey="0" flush>
                        {schema && Object.keys(schema).map((item, idx) => {
                            return (
                            <Accordion.Item eventKey={idx}>
                                <Accordion.Header>{item}</Accordion.Header>
                                <Accordion.Body>

                                    <small className=" text-black-50">Tables</small>

                                    <ul className="list-group list-group-flush mt-1">
                                        {schema[item].map((table, table_idx) => {
                                            return (
                                            <li className="list-group-item" style={{ fontSize: "14px" }} key={table_idx}>{table}</li>
                                            )
                                        })}
                                    </ul>

                                </Accordion.Body>
                            </Accordion.Item>
                            )
                        })}
                    </Accordion>
                    }

                </div>
            </div>
        </div>
    )

}
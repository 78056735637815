import React, { useState, useContext } from "react"
import axios from "axios"
import { useHistory, useParams, Link } from "react-router-dom"
import moment from "moment"
import UserContext from "../context/user"

import vendor_logo_postgresql from "../assets/images/vendor/postgresql_color.svg"
import vendor_logo_mysql from "../assets/images/vendor/mysql_color.svg"

const ReportsModule = ({ connection = null }) => {

    let [loading, setLoading] = useState(false)
    let [reports, setReports] = useState([])

    const [Auth_user, setAuth_user] = useContext(UserContext);

    React.useEffect(() => {
        getReports();        

    }, [])

    const getReports = () => {

        setLoading(true)

        axios.get(window.REACT_APP_API_ENDPOINT + "/reports").then((res) => {

            console.log(res.data)

            setReports(res.data)
            setLoading(false)

        })

    }

    let history = useHistory()
    const viewRecord = (e, id) => {
        history.push("/reports/" + id);
    }

    const viewQuery = (e, id) => {
        e.stopPropagation()
        history.push("/queries/" + id);
    }


    return (
        <>

            <div className="container-fluid m-0 py-3  shadow-sm bg-white">
                <div className="row">
                    <div className="col-12 mb-3 md-md-0 col-md-6">
                        {loading ? "Loading reports..." : reports.length + " reports" }
                    </div>
                    {/* <div className="col-12 col-md-6 text-md-end">
                        {["owner", "admin", "user"].indexOf(Auth_user.default_team.role) > -1 &&
                        <Link to={"/new-query" + (connection ? "?connection=" + connection : "")} className="btn btn-md btn-secondary text-white rounded-5 rounded-pill"><i className="bi bi-plus-circle me-1"></i> Create a new query</Link>
                        }
                    </div> */}
                    {!loading && reports.length > 0 && 
                    <div className="col-12 mt-4">

                        <div className="nowrap" style={{
                        overflowX: "auto"
                    }}>

                            <table className={"table table-hover"}>
                                <thead>
                                    <tr>
                                        <th scope="col" className="fw-light">Name</th>
                                        <th scope="col" className="fw-light">Query</th>
                                        <th scope="col" className="fw-light">Last modified by</th>
                                        <th scope="col" className="fw-light">Last modified</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reports && reports.map((item, index) => {
                                        return (
                                            <tr style={{ cursor: "pointer" }} onClick={e => viewRecord(e, item.id)}>
                                                <td>{item.name}</td>
                                                <td><button className="btn btn-link p-0 m-0" onClick={e => viewQuery(e, item.query)}>{item.query_name}</button></td>
                                                <td>{item.updated_by_email}</td>
                                                <td>{moment.utc(item.updated_at).local().fromNow()}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>

                        </div>

                    </div>
                    }
                </div>
            </div>

        </>
    )

}

export default ReportsModule;
import React, { useState, useContext } from "react"
import axios from "axios"
import UserContext from "../../context/user"

const Teams = () => { 

    let [loading, setLoading] = useState(false)
    let [teams, setTeams] = useState([])

    const [Auth_user, setAuth_user] = useContext(UserContext);

    React.useEffect(() => {

        getTeams();        

    }, [])

    const getTeams = () => {

        setLoading(true)

        axios.get(window.REACT_APP_API_ENDPOINT + "/teams").then((res) => {

            setTeams(res.data)
            setLoading(false)

        })

    }

    const setTeam = (e, id) => {

        setLoading(true)

        axios.put(window.REACT_APP_API_ENDPOINT + "/default-team", {
            team: id
        }).then((res) => {

            window.location.href = "/teams"
            
        })

    }

    return (
        <>
        <div className="container-fluid m-0 py-5 bg-light">
            <div className="row">
                <div className="col-12">

                    <div className="container mx-auto p-0">
                        <h2>Teams</h2>
                        <p>Teams you're a member of. Select an option below to switch teams.</p>
                        {loading ? "Loading..." : "" }
                    </div>

                </div>
            </div>
            <div className="row">
                <div className="col-12">

                    <div className="container mx-auto p-0">
                        <div className="row">
                        {!loading && teams && teams.map((item, index) => {
                            return (
                                <div className="col-12 col-md-4 pt-4">
                                    <div onClick={e => setTeam(e, item.id)} className={"card " + ((Auth_user.default_team.id == item.id) ? "border-secondary border-2 text-secondary" : "")} style={{ cursor: "pointer"}}>
                                        <div className="card-body">
                                            <h5 className="card-title">{item.name}</h5>
                                            <p>
                                                {(Auth_user.default_team.id == item.id) ?
                                                    <>
                                                    <i class="bi bi-check-circle-fill me-1"></i> Currently selected
                                                    </>
                                                    :
                                                    <>
                                                    Select this team
                                                    </>
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        </div>
                    </div>

                </div>
            </div>
        </div>

        </>
    )

}

export default Teams;



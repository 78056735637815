import React, {useState, useContext} from 'react'
import Auth from "@aws-amplify/auth"
import UserContext from "../../context/user"

const Register = () => {

    let [form, setForm] = useState({
        email: "",
        org: "",
        password: "",
        password2: "",
        error: false,
        success: false,
        loading: false
    })

    const [Auth_user, setAuth_user] = useContext(UserContext);

    const registerUser = (event) => {

        event.preventDefault()

        if(form.password !== form.password2){

            form = { ...form }
            form.loading = false
            form.success = false
            form.error = "Password fields do not match"
            setForm(form)
            return
        }

        form = { ...form }
        form.loading = true
        form.success = false
        form.error = false
        setForm(form)

        Auth.signUp({
            username: form.email,
            password: form.password,
            attributes: {
                "custom:team_name": form.org
            }
        }).then(user => {

            form = { ...form }
            form.success = "Account successfully created. Signing you in..."
            form.error = false
            setForm(form)

            Auth.signIn(form.email, form.password).then((response) => {

                setAuth_user(response.username)

                window.gtag('set', 'userId', response.attributes.sub);

                form = { ...form }
                form.loading = false
                form.success = "User successfully signed in"
                form.error = false
                setForm(form)

                window.location.assign("/")


            }).catch((error) => {

                form = { ...form }
                form.loading = false
                form.success = false
                form.error = error.message
                setForm(form)

            });


        }).catch(error => {

            form = { ...form }
            form.loading = false
            form.success = false
            form.error = error.message
            setForm(form)

        });

    }

    const fieldChange = (event) => {

        form[event.target.id] = event.target.value
        setForm(form)

    }

    return (
        <form className="card px-0 px-md-3 py-4 pb-5 border-0" onSubmit={registerUser}>
            <div className="container">
                <div className="row">
                    <div className="col-12 pb-3">
                        <h4>Create an account</h4>
                    </div>
                    <div className="col-12 pb-3">
                        <input id="org" type="text" required placeholder="Team name" className="form-control p-2 ps-3 rounded-pill bg-light" disabled={(form.loading) ? "disabled" : ""} value={form.org.value} onChange={fieldChange} />
                    </div>
                    <div className="col-12 pb-3">
                        <input id="email" type="email" required placeholder="Email" className="form-control p-2 ps-3 rounded-pill bg-light" disabled={(form.loading) ? "disabled" : ""} value={form.email.value} onChange={fieldChange} />
                    </div>
                    <div className="col-12 pb-3">
                        <input id="password" type="password" placeholder="Password" required className="form-control p-2 ps-3 rounded-pill bg-light" disabled={(form.loading) ? "disabled" : ""} value={form.password.value} onChange={fieldChange} />
                    </div>
                    <div className="col-12 pb-3">
                        <input id="password2" type="password" placeholder="Re-enter password" required className="form-control p-2 ps-3 rounded-pill bg-light" disabled={(form.loading) ? "disabled" : ""} value={form.password2.value} onChange={fieldChange} />
                    </div>
                    <div className={"col-12 " + ((form.error) ? "" : "d-none")}>
                        <div className="alert rounded-pill ps-3 py-2 alert-danger">
                            {form.error}
                        </div>
                    </div>
                    <div className={"col-12 " + ((form.success) ? "" : "d-none")}>
                        <div className="alert rounded-pill ps-3 py-2 alert-success">
                            {form.success}
                        </div>
                    </div>
                    <div className="col-12">
                        <button type="submit" className={"btn btn-secondary rounded-pill text-white btn-md w-100 " + ((form.loading) ? "disabled" : "")} disabled={(form.loading) ? "disabled" : ""}>
                            <span className={"spinner-grow spinner-grow-sm " + ((form.loading) ? "" : "d-none")} role="status" aria-hidden="true"></span>
                            <span className={(form.loading) ? "d-none" : ""}>Create account</span>
                            <span className={(form.loading) ? "" : "d-none"}></span>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )

}

export default Register
import React, { useState } from "react"
import axios from "axios"

import Accordion from 'react-bootstrap/Accordion'

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-mysql";
import "ace-builds/src-noconflict/theme-monokai";

const Explore = () => { 

    let [loading, setLoading] = useState(false)
    let [connection, setConnection] = useState({})

    React.useEffect(() => {
    
    }, [])

    return (
        <>
        <div className="container-fluid vh-100 p-0 m-0">
            <div className="row">
                <div className="col-2 p-3 m-0 bg-white">
                    <small>Team space</small>
                    <Connections connection={connection} setConnection={setConnection} />
                    <br />

                    <small>Connection</small>
                    <Connections connection={connection} setConnection={setConnection} />
                    <br />
                    <Schema connection={connection} />
                    
                </div>
                <div className="col-10 p-0 m-0">

                    <Workspace />

                </div>
            </div>
        </div>
        </>
    )

}

export default Explore;



const Connections = ({ connection, setConnection }) => {

    let [connections, setConnections] = useState([])
    let [loading, setLoading] = useState(true)

    React.useEffect(() => {
        getConnections()
    }, [])

    const getConnections = () => {

        setLoading(true)

        axios.get(window.REACT_APP_API_ENDPOINT + "/connections").then((res) => {

            setLoading(false)
            setConnections(res.data)

        })

    }

    const handleChange = (e) => {

        connections.map((item, index) => {
            if(item.id == e.target.value){
                setConnection(item)
            }
        })

    }

    return (
    <div className="form p-0 m-0">
        <select
            type="text"
            className={"form-control"}
            name={"connection"}
            value={connection && connection.id || ""}
            placeholder={loading ? "Loading..." : "Choose connection"}
            disabled={loading}
            onChange={(e) => handleChange(e)} >
            {connections && connections.map((item, index) => {
                return (
                    <option value={item.id} key={index}>{item.name}  ({item.id})</option>
                )
            })}

        </select>
    </div>
    )

}

const Schema = ({ connection }) => {

    let [loading, setLoading] = useState();
    let [schema, setSchema] = useState([]);

    React.useEffect(() => {
        if(connection){
            getSchema()
        }
    }, [connection])

    const getSchema = () => {
        setLoading(true)

        axios.get(window.REACT_APP_API_ENDPOINT + "/" + connection.type + "/schema", {
            "params": {
                "connection": connection.id
            }
        }).then((res) => {
            setSchema(res.data)
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
        })

    }

    return (
        <>
        <div className="container-fluid p-0 m-0">
            <div className="row p-0 m-0">
                <div className="col-12 p-0 m-0">
                    <small>Schemas</small>
                    {schema &&
                    <Accordion defaultActiveKey="0" flush>
                        {schema && Object.keys(schema).map((item, idx) => {
                            return (
                            <Accordion.Item eventKey={idx}>
                                <Accordion.Header>{item}</Accordion.Header>
                                <Accordion.Body>

                                    <small className=" text-black-50">Tables</small>

                                    <ul className="list-group list-group-flush mt-1">
                                        {schema[item].map((table, table_idx) => {
                                            return (
                                            <li className="list-group-item" style={{ fontSize: "14px" }} key={table_idx}>{table}</li>
                                            )
                                        })}
                                    </ul>

                                </Accordion.Body>
                            </Accordion.Item>
                            )
                        })}
                    </Accordion>
                    }
                </div>
            </div>
        </div>
        </>
    )

}


const Workspace = () => {

    let [loading, setLoading] = useState()

    let [tabs, setTabs] = useState([])

    React.useEffect(() => {

    }, [])

    const addTab = (e) => {
        e.preventDefault()
        e.stopPropagation()

        tabs = [...tabs]
        tabs.push({
            "title": "tab yo"
        })
        setTabs(tabs)
    }

    const removeTab = (e, index) => {
        e.preventDefault()
        e.stopPropagation()

        tabs = [...tabs]
        tabs.splice(index, 1)
        setTabs(tabs)

    }

    return (
        <>
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#">Active <i class="bi bi-x ms-3"></i></a>
            </li>
            {tabs.map((item, index) => {
                return (
                <li class="nav-item" key={index}>
                    <a class="nav-link">{item.title} <i class="bi bi-x ms-3" onClick={e => removeTab(e, index)}></i></a>
                </li>
                )
            })}
            <li class="nav-item">
                <a class="nav-link" onClick={e => addTab(e)}><i class="bi bi-plus"></i></a>
            </li>
        </ul>

        <AceEditor
            mode="mysql"
            theme="monokai"
            // onChange={value => queryChange(value)}
            name="sql"
            // value={query}
            readOnly={loading ? true : false}
            style={{
                width: "100%",
                height: "200px",
                opacity: (loading ? "0.2" : ""),
                fontSize: "1rem"
            }}
            editorProps={{ $blockScrolling: true }}
        />
        </>
    )

}
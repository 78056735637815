import React from "react"
import {
    Link
} from "react-router-dom";
import {Helmet} from "react-helmet";

const Pricing = () => {

    let year = new Date().getFullYear();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Pricing - it's free | DB Ease</title>
            </Helmet>
            <div className="container p-0 m-0 mx-auto">
                <div className="row m-0 p-0 pb-5">
                    <div className="col-12 p-0 m-0 py-5 text-center">

                        <h2 className="mb-3">Pricing</h2>
                        <p className="col-12 col-md-6 mx-auto p-3">We're still in beta. However, some teams may be interested in enterprise support. Check back often as we offer more flexible pricing tiers.</p>

                        <div className="container-fluid col-10 mt-5">
                            <div className="row gx-md-5 ">
                                <div className="col-12 col-md-6 text-start">

                                    <div className="shadow-sm border border-2 p-4">
                                    <h5>Basic</h5>

                                    <h6>Free forever</h6>

                                    <span className="mt-4 d-block">
                                        <i class="bi bi-check-circle me-2"></i> <strong>5</strong> users
                                    </span>
                                    <span className="mt-2 d-block">
                                        <i class="bi bi-check-circle me-2"></i> <strong>5</strong> database connections
                                    </span>
                                    {/* <span className="mt-2 d-block">
                                        <i class="bi bi-check-circle me-2"></i> <strong>10</strong> reports
                                    </span> */}
                                    <span className="mt-2 d-block">
                                        <i class="bi bi-check-circle me-2"></i> <strong>10k</strong> query operations per month
                                    </span>
                                    <span className="mt-2 d-block">
                                        <i class="bi bi-check-circle me-2"></i>  Data API
                                    </span>
                                    <span className="mt-2 d-block">
                                        <i class="bi bi-check-circle me-2 text-white"></i> 
                                    </span>
                                    <span className="mt-2 d-block">
                                        <i class="bi bi-check-circle me-2 text-white"></i> 
                                    </span>
                                    <span className="mt-2 d-block mb-4">
                                        <i class="bi bi-check-circle me-2 text-white"></i> 
                                    </span>

                                    <hr />

                                    <Link to="/sign-up" className="btn btn-secondary btn-md text-white w-100 rounded-pill mt-3">Get started free</Link>

                                    </div>
                                    
                                </div>
                                <div className="col-12 col-md-6 mt-5 mt-md-0 text-start">

                                    <div className="bg-light shadow-sm  border border-2  p-4">
                                    <h5>Enterprise</h5>
                                    <h6>Contact us for pricing</h6>

                                    <span className="mt-4 d-block">
                                        <i class="bi bi-check-circle me-2"></i> <strong>Unlimited</strong> users
                                    </span>
                                    <span className="mt-2 d-block">
                                        <i class="bi bi-check-circle me-2"></i> <strong>Unlimited</strong> database connections
                                    </span>
                                    {/* <span className="mt-2 d-block">
                                        <i class="bi bi-check-circle me-2"></i> <strong>Unlimited</strong> reports
                                    </span> */}
                                    <span className="mt-2 d-block">
                                        <i class="bi bi-check-circle me-2"></i> <strong>Billions</strong> of query operations per month
                                    </span>
                                    <span className="mt-2 d-block">
                                        <i class="bi bi-check-circle me-2"></i> Data API
                                    </span>
                                    <span className="mt-2 d-block">
                                        <i class="bi bi-check-circle me-2"></i> Virtual firewall
                                    </span>
                                    <span className="mt-2 d-block">
                                        <i class="bi bi-check-circle me-2"></i> Dedicated IP address
                                    </span>
                                    <span className="mt-2 d-block mb-4">
                                        <i class="bi bi-check-circle me-2"></i> Single sign-on (SSO)
                                    </span>

                                    <hr />

                                    <Link to="/contact" className="btn btn-outline-dark border-2 rounded-pill w-100 mt-3">Contact us</Link>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>

    )
};

export default Pricing;
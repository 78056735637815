import React from "react"
import {
    Link
} from "react-router-dom";
import {Helmet} from "react-helmet";

import ImgQuery from '../../assets/images/marketing/home/query.png';
import ImgTeamManagement from '../../assets/images/marketing/home/team-management.png';
import ImgStoreConnections from '../../assets/images/marketing/home/store-connections.png';
import ImgSaveQuery from '../../assets/images/marketing/home/save-query.png';
import ImgTestConnections from '../../assets/images/marketing/home/test-connections.png';
import ImgSelectTeam from '../../assets/images/marketing/home/select-team.png';
import ImgDownloadResults from '../../assets/images/marketing/home/download-results.png';
import ImgMobile from '../../assets/images/marketing/home/mobile.png';

import vendor_logo_postgresql from "../../assets/images/vendor/postgresql_color.svg"
import vendor_logo_mysql from "../../assets/images/vendor/mysql_color.svg"
import vendor_logo_aws from "../../assets/images/vendor/aws_color.svg"

const HomeGuest = () => {

    React.useEffect(() => {

    }, [])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Free web-based SQL client for MySQL and PostgreSQL | DB Ease</title>
            </Helmet>
            <div className="container-fluid m-0 p-0 bg-white">
                <div className="row m-0 p-0">
                    <div className="col-12 text-center pt-5 py-md-5">
                        <h1>A collaborative, web-based SQL client that's free to use</h1>
                    </div>
                </div>
                <div className="row m-0 p-0">
                    <div className="col-12 text-center pb-5 pt-4 pt-md-0">
                        <Link to="/sign-up" className="btn btn-lg btn-secondary rounded-pill text-white px-3">Sign up</Link>
                    </div>
                </div>
                <div className="row m-0 p-0 pb-4">
                    <div className="col-12">
                        <div className="container mx-auto p-0 m-0">
                            <div className="row m-0 p-0">
                                <div className="col-12 col-md-8 text-center p-0 m-0 pb-5 px-2 px-md-0 mx-auto">

                                    <img src={ImgQuery} style={{ width: "100%" }} className="shadow-sm border border-2 border-lessdark p-3" />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row bg-light m-0 p-0">
                    <div className="col-12 py-5 text-center">

                        <div className="container mx-auto">
                            <div className="row mx-auto text-center">
                                <div className="col-12 py-4">
                                    <h2 className="mb-3">Built in support for Amazon Aurora, MySQL, and PostgreSQL</h2>
                                    <p>Support for more of your favorite databases on the way.</p>
                                </div>
                                <div className="col-12 py-4">
                                    <img src={vendor_logo_postgresql} className="me-5 col-12 mb-4 col-md-2" style={{ height: "100%", maxHeight: "70px" }}></img>
                                    <img src={vendor_logo_mysql} className="me-5 col-12 mb-5 col-md-2" style={{ height: "100%", maxHeight: "70px" }}></img>
                                    <img src={vendor_logo_aws} className="col-12 col-md-2" style={{ height: "100%", maxHeight: "70px" }}></img>
                                </div>
                            </div>
                        </div>




                    </div>
                </div>
                <div className="row m-0 p-0">
                    <div className="col-12 py-5">

                        <div className="container">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h2 className="mb-3">Features</h2>
                                    <p>Everything you need to connect, query, report, and collaborate.</p>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-12 col-md-6 p-0 p-md-5 text-center bg-light order-last order-md-first mt-4 m-md-0">
                                    <img src={ImgTeamManagement} style={{ width: "100%", maxWidth: "400px" }} className="shadow-sm border border-2 border-lessdark p-3 bg-white" />
                                </div>
                                <div className="col-12 col-md-6 p-md-5 text-center text-md-start">
                                    <i class="bi bi-people" style={{ fontSize: "4rem" }}></i>
                                    <h4 className="d-block mt-3 mb-3">Bring your team with you</h4>
                                    <p>Add your team and share database connections and queries across your org. Manage user-level permissions across multiple team spaces.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row bg-reallydark m-0 p-0">
                    <div className="col-12 py-5">

                        <div className="container">

                            <div className="row text-white">
                                <div className="col-12 col-md-6 p-md-5 text-center text-md-start">
                                    <i class="bi bi-safe" style={{ fontSize: "4rem" }}></i>
                                    <h4 className="d-block mt-3 mb-3"> Securely store your database connections</h4>
                                    <p>Share them your team, or use them for querying and exporting data from your database.</p>
                                </div>
                                <div className="col-12 col-md-6 p-0 p-md-5 text-center text-md-start mt-4 m-md-0">
                                    <img src={ImgStoreConnections} style={{ width: "100%", maxWidth: "600px" }} className="shadow-sm p-3 bg-white" />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="row m-0 p-0">
                    <div className="col-12 py-5">

                        <div className="container">

                            <div className="row">
                                <div className="col-12 col-md-6 p-0 p-md-5 text-center bg-light order-last order-md-first mt-4 m-md-0">
                                    <img src={ImgSaveQuery} style={{ width: "100%", maxWidth: "400px" }} className="shadow-sm border border-2 border-lessdark p-3 bg-white" />
                                </div>
                                <div className="col-12 col-md-6 p-md-5 text-center text-md-start">
                                    <i class="bi bi-terminal" style={{ fontSize: "4rem" }}></i>
                                    <h4 className="d-block mt-3 mb-3"> Save SQL queries and run them across saved connections</h4>
                                    <p>Save your SQL queries and share them with your teammates. You can apply saved SQL queries to any saved connection within your team space.</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="row bg-reallydark m-0 p-0">
                    <div className="col-12 py-5">

                        <div className="container">

                            <div className="row text-white">
                                <div className="col-12 col-md-6 p-md-5 text-center text-md-start">
                                    <i class="bi bi-broadcast" style={{ fontSize: "4rem" }}></i>
                                    <h4 className="d-block mt-3 mb-3"> Explore schemas and test connections</h4>
                                    <p>Easily navigate server schemas and test connections on the fly to debug connectivity issues in your application.</p>
                                </div>
                                <div className="col-12 col-md-6 p-0 p-md-5 text-center text-md-start mt-4 m-md-0">
                                    <img src={ImgTestConnections} style={{ width: "100%", maxWidth: "400px" }} className="shadow-sm p-3 bg-white" />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="row m-0 p-0">
                    <div className="col-12 py-5">

                        <div className="container">

                            <div className="row">
                                <div className="col-12 col-md-6 p-0 p-md-5 text-center bg-light order-last order-md-first mt-4 m-md-0">
                                    <img src={ImgSelectTeam} style={{ width: "100%", maxWidth: "500px" }} className="shadow-sm border border-2 border-lessdark p-3 bg-white" />
                                </div>
                                <div className="col-12 col-md-6 p-md-5 text-center text-md-start">
                                    <i class="bi bi-signpost-2" style={{ fontSize: "4rem" }}></i>
                                    <h4 className="d-block mt-3 mb-3"> Easily navigate between multiple team spaces</h4>
                                    <p>Seamlessly switch between team spaces without signing out.</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="row bg-reallydark m-0 p-0">
                    <div className="col-12 py-5">

                        <div className="container">

                            <div className="row text-white">
                                <div className="col-12 col-md-6 p-md-5 text-center text-md-start">
                                    <i class="bi bi-cloud-download" style={{ fontSize: "4rem" }}></i>
                                    <h4 className="d-block mt-3 mb-3"> Download query results</h4>
                                    <p>Export data directly from your database queries in either CSV or JSON format.</p>
                                </div>
                                <div className="col-12 col-md-6 p-0 p-md-5 text-center text-md-start mt-4 m-md-0">
                                    <img src={ImgDownloadResults} style={{ width: "100%", maxWidth: "400px" }} className="shadow-sm p-3 bg-white" />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="row m-0 p-0">
                    <div className="col-12 py-5">

                        <div className="container">

                            <div className="row">
                                <div className="col-12 col-md-6 p-0 p-md-5 text-center bg-light order-last order-md-first mt-4 m-md-0">
                                    <img src={ImgMobile} style={{ width: "100%", maxWidth: "320px" }} className="shadow-sm border border-2 border-lessdark p-3 bg-white" />
                                </div>
                                <div className="col-12 col-md-6 p-md-5 text-center text-md-start">
                                    <i class="bi bi-phone" style={{ fontSize: "4rem" }}></i>
                                    <h4 className="d-block mt-3 mb-3"> It also works on mobile</h4>
                                    <p>For the overachiever.</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="row m-0 p-0">
                    <div className="col-12 text-center bg-light py-5 align-middle">
                        <h2 className="mb-3">Oh yeah, and there's more</h2>
                        <p className="mb-4">Get started in seconds (it's free).</p>
                        <Link to="/sign-up" className="btn btn-lg btn-primary rounded-pill px-3">Sign up</Link>
                    </div>
                </div>
            </div >

        </>
    )

}

export default HomeGuest;

import React, { useState, useContext } from "react"
import axios from "axios"
import { Link, useHistory } from "react-router-dom"
import UserContext from "../../context/user"

import CreateConnection from "../../components/create-connection"

import vendor_logo_postgresql from "../../assets/images/vendor/postgresql_color.svg"
import vendor_logo_mysql from "../../assets/images/vendor/mysql_color.svg"
import vendor_logo_aws from "../../assets/images/vendor/aws_color.svg"

const Home = () => {

    let [loading, setLoading] = useState(false)
    let [connections, setConnections] = useState([])

    const [Auth_user, setAuth_user] = useContext(UserContext);

    const history = useHistory();

    React.useEffect(() => {

        getConnections();

        axios.get(window.REACT_APP_API_ENDPOINT + "/reports").then((res) => {
            console.log(res.data)
        })

    }, [])

    const getConnections = () => {

        setLoading(true)

        axios.get(window.REACT_APP_API_ENDPOINT + "/connections").then((res) => {

            setConnections(res.data)
            setLoading(false)

        })

    }

    const openConnection = (e, id) => {
        history.push("/connections/" + id);
    }

    const VendorLogo = ({type}) => {

        let img = ""

        switch(type){
            case "mysql":
                img = vendor_logo_mysql
                break;
            case "postgresql":
                img = vendor_logo_postgresql
                break;
            case "aurora-mysql":
                img = vendor_logo_mysql
                break;
            case "aurora-postgresql":
                img = vendor_logo_postgresql
                break;
        }

        return (
            <img src={img} className="my-3 text-start d-inline" style={{ height: "100%", maxHeight: "45px" }} />
        )

    }

    return (
        <>
            <div className="container-fluid m-0 py-5 bg-light">
                <div className="row">
                    <div className="col-12">

                        <div className="container mx-auto p-0">
                            <div className="row">
                                <div className="col-6">
                                    <h2>Connections</h2>
                                    {loading ? "Loading..." : ""}
                                </div>
                                <div className="col-6">
                                    {["owner", "admin", "user"].indexOf(Auth_user.default_team.role) > -1 &&
                                        <CreateConnection callback={getConnections} />
                                    }
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className="col-12">

                        <div className="container mx-auto p-0">
                            <div className="row">
                                {!loading && connections && connections.map((item, index) => {
                                    return (
                                        <div className="col-12 col-md-4 pt-4">
                                            <div onClick={e => openConnection(e, item.id)} className="card" style={{ cursor: "pointer" }}>
                                                <div className="card-body">
                                                    
                                                    <VendorLogo type={item.type} />

                                                    <h4 className="card-title">{item.name}</h4>
                                                    <p className="card-text">
                                                        <span className="d-block small" style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}><b>Type</b> {item.type}</span>
                                                        <span className="d-block small" style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}><b>Host</b> {item.credentials.host}</span>
                                                        <span className="d-block small" style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}><b>Username</b> {item.credentials.username}</span>
                                                    </p>
                                                    <Link to={"/connections/" + item.id} className="card-link">Open connection</Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                {!loading && connections.length < 1 &&
                                    <div className="col-12 text-center py-5">
                                        No connections. Click <strong>Create a new connection</strong> above to create one.
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )

}

export default Home;



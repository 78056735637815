import React, { useState } from "react"
import axios from "axios"

const TestConnection = ({ connection = null, type = null }) => {

    let [loading, setLoading] = useState(false)
    let [error, setError] = useState(false)
    let [about, setAbout] = useState()

    React.useEffect(() => {

        if(connection && type){
            getAbout();        
        }

    }, [connection, type])

    const getAbout = () => {

        setLoading(true)

        axios.post(window.REACT_APP_API_ENDPOINT + "/" + type + "/about", {
            "connection": connection 
        }).then((res) => {

            setAbout(res.data)
            setError(false)
            setLoading(false)

        }).catch((err) => {

            setAbout()
            setError(true)
            setLoading(false)

        })

    }

    return (
        <>
            <div className="container-fluid m-0 p-0">
                <div className="row">
                    <div className="col-12">
                        {loading &&
                        <>
                        <div className="spinner-border spinner-border-sm text-primary me-2" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        Testing connection...
                        </>
                        }

                        {!loading && !error &&
                        <span className="text-success"><i class="bi bi-check-circle me-1"></i> Connection successful</span>
                        }

                        {!loading && error && 
                        <span className="text-danger"><i className="bi bi-exclamation-triangle me-2"></i> Connection unsuccessful</span>
                        }

                        {!loading &&
                        <button className="btn btn-link" onClick={e => getAbout(e)}>Test connection</button>
                        }

                    </div>
                </div>
            </div>
        </>
    )

}

export default TestConnection;
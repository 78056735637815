import React, {useState, useContext} from 'react'
import UserContext from "../../context/user"
import Auth from "@aws-amplify/auth"

const ForgotPassword = () => {

    let [form, setForm] = useState({
        email: false,
        code: false,
        code_disabled: true,
        password: false,
        password2: false,
        error: false,
        success: false,
        loading: false
    })

    const [Auth_user, setAuth_user] = useContext(UserContext);

    const SendConfirmationCode = (event) => {

        event.preventDefault()

        form = {...form}
        form.loading = true
        form.success = false
        form.error = false
        setForm(form)

        Auth.forgotPassword(form.email).then((response) => {

            form = { ...form }
            form.loading = false
            form.success = "Recovery code sent"
            form.code_disabled = false;
            form.error = false
            setForm(form)

        }).catch((error) => {

            form = { ...form }
            form.loading = false
            form.success = false
            form.error = error.message
            setForm(form)

        });

    }

    const ResetPassword = (event) => {

        event.preventDefault()

        if (form.password !== form.password2) {

            form = { ...form }
            form.loading = false
            form.success = false
            form.error = "Password fields do not match"
            setForm(form)
            return
        }

        form = { ...form }
        form.loading = true
        form.success = false
        form.error = false
        setForm(form)

        Auth.forgotPasswordSubmit(form.email, form.code, form.password).then((response) => {

            form = { ...form }
            form.loading = true
            form.success = "Password successfully reset. Signing in..."
            form.code_disabled = false;
            form.error = false
            setForm(form)

            Auth.signIn(form.email, form.password).then((response) => {

                setAuth_user(response.username)

                form = { ...form }
                form.loading = false
                form.success = "User successfully signed in"
                form.error = false
                setForm(form)


            }).catch((error) => {

                form = { ...form }
                form.loading = false
                form.success = false
                form.error = error.message
                setForm(form)

            });

        }).catch((error) => {

            form = { ...form }
            form.loading = false
            form.success = false
            form.error = error.message
            setForm(form)

        });

    }

    const fieldChange = (event) => {

        form[event.target.id] = event.target.value
        setForm(form)

    }

    if(form.code_disabled){
    return (
        <form className="card px-0 px-md-3 py-4 pb-5 border-0" onSubmit={SendConfirmationCode}>
            <div className="container">
                <div className="row">
                    <div className="col-12 pb-3">
                        <h4>Password recovery</h4>
                        <p>Please enter the email associated with your account</p>
                    </div>
                    <div className="col-12 pb-3">
                        <input id="email" type="email" required placeholder="Email" className="form-control p-2 ps-3 rounded-pill bg-light" disabled={(form.loading || !form.code_disabled) ? "disabled" : ""} value={form.email.value} onChange={fieldChange} />
                    </div>
                    <div className={"col-12 " + ((form.error) ? "" : "d-none")}>
                        <div className="alert alert-danger">
                            {form.error}
                        </div>
                    </div>
                    <div className={"col-12 " + ((form.success) ? "" : "d-none")}>
                        <div className="alert alert-success">
                            {form.success}
                        </div>
                    </div>
                    <div className="col-12">
                        <button type="submit" className={"btn btn-secondary btn-md rounded-pill w-100 text-white " + ((form.loading) ? "disabled" : "")} disabled={(form.loading) ? "disabled" : ""}>
                            <span className={"spinner-grow spinner-grow-sm " + ((form.loading) ? "" : "d-none")} role="status" aria-hidden="true"></span>
                            <span className={(form.loading) ? "d-none" : ""}>Send verification code</span>
                            <span className={(form.loading) ? "" : "d-none"}></span>
                        </button>
                    </div>
                    <div className="col-12 py-4">
                        <a href="#" className="text-secondary" onClick={() => { window.location.reload() }}>I remembered my password, I want to sign in</a>
                    </div>
                </div>
            </div>
        </form>
    )
    } else {
    return (
        <form className="card px-0 px-md-3 py-4 pb-5 border-0" onSubmit={ResetPassword}>
            <div className="container">
                <div className="row">
                    <div className="col-12 pb-3">
                        <h4>Password recovery</h4>
                        <p>Please enter the code that was sent to your email and provide a new password to access your account</p>
                    </div>
                    <div className="col-12 pb-3">
                        <input id="email" type="email" placeholder="Email" className="form-control p-2 ps-3 rounded-pill bg-light" disabled value={form.email.value} />
                    </div>

                    <div className="col-12 pb-3">
                        <input id="code" type="text" placeholder="Verification code" className="form-control p-2 ps-3 rounded-pill bg-light" disabled={(form.loading) ? "disabled" : ""} value={form.code.value} onChange={fieldChange} />
                    </div>
                    <div className="col-12 pb-3">
                        <input id="password" type="password" required placeholder="New password" className="form-control p-2 ps-3 rounded-pill bg-light" disabled={(form.loading) ? "disabled" : ""} value={form.password.value} onChange={fieldChange} />
                    </div>
                    <div className="col-12 pb-3">
                        <input id="password2" type="password" required placeholder="Re-enter new password" className="form-control p-2 ps-3 rounded-pill bg-light" disabled={(form.loading) ? "disabled" : ""} value={form.password2.value} onChange={fieldChange} />
                    </div>
                    <div className={"col-12 " + ((form.error) ? "" : "d-none")}>
                        <div className="alert alert-danger">
                            {form.error}
                        </div>
                    </div>
                    <div className={"col-12 " + ((form.success) ? "" : "d-none")}>
                        <div className="alert alert-success">
                            {form.success}
                        </div>
                    </div>
                    <div className="col-12">
                        <button type="submit" className={"btn btn-secondary btn-md rounded-pill w-100 text-white " + ((form.loading) ? "disabled" : "")} disabled={(form.loading) ? "disabled" : ""}>
                            <span className={"spinner-grow spinner-grow-sm " + ((form.loading) ? "" : "d-none")} role="status" aria-hidden="true"></span>
                            <span className={(form.loading) ? "d-none" : ""}>Reset password</span>
                            <span className={(form.loading) ? "" : "d-none"}></span>
                        </button>
                    </div>
                    <div className="col-12 py-4">
                        <a href="#" className="text-secondary" onClick={() => { window.location.reload() }}>I remembered my password, I want to sign in</a>
                    </div>
                </div>
            </div>
        </form>
    )
    }

}

export default ForgotPassword
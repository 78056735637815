import React, { useState, useContext } from "react"
import axios from "axios"
import { useHistory, useParams } from "react-router-dom"
import moment from "moment"
import UserContext from "../../context/user"

import QueriesModule from "../../components/queries"
import TestConnection from "../../components/test-connection"
import EditConnection from "../../components/edit-connection"

import vendor_logo_postgresql from "../../assets/images/vendor/postgresql_color.svg"
import vendor_logo_mysql from "../../assets/images/vendor/mysql_color.svg"

const Connection = () => {

    let [loading, setLoading] = useState(false)
    let [connection, setConnection] = useState([])

    const [Auth_user, setAuth_user] = useContext(UserContext);

    const { id } = useParams()

    React.useEffect(() => {

        getConnection();

    }, [])

    const getConnection = () => {

        setLoading(true)

        axios.get(window.REACT_APP_API_ENDPOINT + "/connections/" + id).then((res) => {

            setConnection(res.data)
            setLoading(false)

        })

    }

    let history = useHistory()

    const deleteConnection = (e) => {

        axios.delete(window.REACT_APP_API_ENDPOINT + "/connections/" + id).then((res) => {

            history.push("/")

        })

    }

    const VendorLogo = ({type}) => {

        let img = ""

        switch(type){
            case "mysql":
                img = vendor_logo_mysql
                break;
            case "postgresql":
                img = vendor_logo_postgresql
                break;
            case "aurora-mysql":
                img = vendor_logo_mysql
                break;
            case "aurora-postgresql":
                img = vendor_logo_postgresql
                break;
        }

        return (
            <img src={img} className="text-start d-inline" style={{ height: "50px" }} />
        )

    }

    return (
        <>
            <div className="container m-0 mx-auto p-0 my-4">
                <div className="row">
                    <div className="col-12">
                        <h5 className="card-title"><h1>{connection.name}</h1></h5>
                    </div>
                </div>
                <div className="row">

                    <div className="col-12 col-md-8">

                        {connection && connection.id && 
                            <QueriesModule connection={connection.id} />
                        }

                    </div>

                    <div className="col-12 col-md-4 mt-4 mt-md-0">

                        <div className="card radius-0 border-0 shadow-sm mb-4">
                            <div className="card-body">

                                <div className="container-fluid m-0 p-3">
                                    <div className="row">

                                        <div className="col-12 text-start mb-4">
                                            <VendorLogo type={connection.type}/>
                                        </div>

                                        <div className="col-12 text-start m-0 p-0 ps-2 mb-3">
                                            <h5 className="m-0 p-0">{connection.name}</h5>
                                        </div>
                                        
                                        <div className="col-12 col-md-6 p-2" style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                                            <strong className="d-block">Type</strong>
                                            {connection.type}
                                        </div>
                                        <div className="col-12 col-md-6 p-2" style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                                            <strong className="d-block">Database</strong>
                                            {connection.credentials && connection.credentials.database || "Not set"}
                                        </div>
                                        <div className="col-12 col-md-6 p-2" style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                                            <strong className="d-block">Username</strong>
                                            {connection.credentials && connection.credentials.username || "Not set"}
                                        </div>
                                        <div className="col-12 col-md-6 p-2 " style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                                            <strong className="d-block">Host</strong>
                                            {connection.credentials && connection.credentials.host || "Not set"}
                                        </div>
                                        <div className="col-12 col-md-6 p-2" style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                                            <strong className="d-block">Port</strong>
                                            {connection.credentials && connection.credentials.port || "Not set"}
                                        </div>

                                        <div className="col-12 ps-2 mt-3">
                                            {(!loading && connection) ? <EditConnection callback={getConnection} parentConnection={connection} /> : "Loading connection..."}
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>

                        <div className="card radius-0 border-0 shadow-sm">
                            <div className="card-body">
                                {connection && !loading &&
                                    <TestConnection connection={connection.id} type={connection.type} />
                                }
                            </div>
                        </div>

                        <div className="card radius-0 border-0 shadow-sm mt-4">
                            <div className="card-body">

                                <div className="container-fluid m-0 p-3">
                                    <div className="row">

                                        <div className="col-12 p-2">
                                            <strong className="d-block">Created by</strong>
                                            {connection.created_by_email} {moment.utc(connection.created_at).local().fromNow()}
                                        </div>
                                        <div className="col-12 p-2">
                                            <strong className="d-block">Last modified by</strong>
                                            {connection.updated_by_email} {moment.utc(connection.updated_at).local().fromNow()}
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>

                        {["owner", "admin", "user"].indexOf(Auth_user.default_team.role) > -1 &&
                            <div className="card radius-0 border-0 shadow-sm mt-4">
                                <div className="card-body">
                                    <button className={"btn btn-outline-danger rounded-pill"} onClick={e => deleteConnection(e)}>Delete connection</button>
                                </div>
                            </div>
                        }


                    </div>

                </div>

            </div>

        </>
    )

}

export default Connection;



import React, { useState } from "react"
import axios from "axios"
import { Link, useHistory } from "react-router-dom"

const CreateReport = ({ callback = null, queryId = null }) => {

    let [loading, setLoading] = useState(false)

    let [queries, setQueries] = useState()

    let [report, setReport] = useState({
        "name": "",
        "description": "",
        "query": queryId
    })

    React.useEffect(() => {
    
    }, [])

    const handleFieldChange = (e, key) => {

        report = {...report}
        report[key] = e.target.value
        setReport(report)

    }

    let history = useHistory()

    const CreateReport = (e, key) => {

        e.preventDefault()

        setLoading(true)

        axios.post(window.REACT_APP_API_ENDPOINT + "/reports", {
            "name": report.name,
            "description": report.description,
            "query": report.query
        }).then(res => {
            setLoading(false)
            window.location.href = "/reports/" + res.data.id

        }).catch(err => {

            console.log(err.response)
        })

    }

    return (
        <>

        <button type="button" className="btn btn-link link-success" data-bs-toggle="modal" data-bs-target="#connectionModal"><i class="bi bi-layers-half me-2"></i> Create report from query</button>


        <div className="modal fade" id="connectionModal" tabindex="-1" aria-labelledby="connectionModalLabel" aria-hidden="true">
            <div className="modal-dialog" style={{ maxWidth: "450px" }}>
                <div className="modal-content">

                    <div className="modal-header">
                        <h5 className="modal-title" id="connectionModalLabel">Create new report</h5>
                        
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <form onSubmit={e => CreateReport(e)}>

                        <div className="modal-body">

                            <div className="container-fluid">
                                <div className="row">

                                    <div className="col-12">

                                        <div className="form-floating">

                                            <select
                                                type="text"
                                                className={"form-control"}
                                                name={"query"}
                                                value={report.query}
                                                disabled="disabled"
                                                placeholder="Query"
                                                onChange={(e) => handleFieldChange(e, "query")} >

                                                <option value={queryId}>{queryId}</option>

                                            </select>

                                            <label for="query">Query</label>

                                        </div>

                                        <div className="form-floating mt-4">

                                            <input
                                                type="text"
                                                className={"form-control"}
                                                name={"name"}
                                                value={report.name}
                                                placeholder="Connection name"
                                                required
                                                onChange={(e) => handleFieldChange(e, "name")} />

                                            <label for="name">Report name</label>

                                        </div>

                                        <div className="form-floating mt-4">

                                            <textarea
                                                type="text"
                                                className={"form-control"}
                                                name={"description"}
                                                value={report.description}
                                                style={{ minHeight: "150px" }}
                                                placeholder="Description"
                                                required
                                                onChange={(e) => handleFieldChange(e, "description")}>
                                            </textarea>

                                            <label for="host">Description</label>

                                        </div>

                                    </div>
                                    

                                </div>
                            </div>

                        </div>

                        <div className="modal-footer">
                            
                            {!loading &&
                            <button type="submit" className="btn btn-secondary mx-auto mx-md-0">Create report</button>
                            }

                            {loading && 
                            <>Loading...</>
                            }

                        </div>

                    </form>

                </div>
            </div>
        </div>

        </>
    )


}

export default CreateReport
import React, { useState, useContext } from "react"
import axios from "axios"
import { useParams, useHistory } from "react-router-dom"
import UserContext from "../../context/user"

import {AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

const Query = () => { 

    // check if id for query is in parameters

    let params = useParams();

    let [reportId, setReportId] = useState(params.id)
    let [loading, setLoading] = useState(false)
    let [report, setReport] = useState()
    let [data, setData] = useState()


    React.useEffect(() => {

        getReport();
        getData();

    }, []);
        
    const getReport = () => {

        setLoading(true)

        axios.get(window.REACT_APP_API_ENDPOINT + "/reports/" + reportId).then((res) => {

            console.log(res.data)

            setReport(res.data)
            setLoading(false)

        })

    }
        
    const getData = () => {

        setLoading(true)

        axios.get(window.REACT_APP_API_ENDPOINT + "/reports/" + reportId + "/data").then((res) => {

            console.log(res.data)

            data = {...data}
            data.headers = []
            data.data = []


            Object.keys(res.data.results[0]).map((item, idx) => {
                data.headers.push({headerName: item, field: item})
            })

            res.data.results.map((item, idx) => {
                data.data.push(item)
            })

            setData(data)
            setLoading(false)

        })

    }


    return (
        <>
            <div className="container-fluid m-0 py-5 bg-light">
                <div className="row">
                    <div className="col-12">

                        {report && 
                        <>
                        <h2>{report.name || "Untitled report"}</h2>
                        <p>{report.description}</p>
                        </>
                        }

                        {loading ? "Loading..." : ""}

                    </div>
                </div>
                <div className="row m-0 p-0">
                    <div className="col-12 m-0 p-0">

                        {data &&
                        <div className="ag-theme-balham shadow-sm border-dark border-1" style={{ width: '100%'}}>
                            <AgGridReact
                                defaultColDef={{ resizable: true, sortable: true, filter: true }}
                                columnDefs={data.headers}
                                paginationPageSize={25}
                                maintainColumnOrder="true"
                                pagination={data.data.length > 25 ? true : false}
                                domLayout='autoHeight'
                                rowData={data.data}>
                            </AgGridReact>                                        
                        </div>
}

                    </div>
                </div>
            </div>
        </>
    )

}

export default Query;

